/*
 * Design Reference – https://codepen.io/juliepark/pen/QxWVPv
 */

/* basic */
*,
*:before,
*:after {
	box-sizing: border-box;
}

.blog {
	font-size: 14px;
	font-weight: bold;
	text-align: center;
	position: absolute;
	bottom: 15px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 0;
}
.alink {
	display: inline-block;
	text-align: center;
	cursor: pointer;
}
input.msg-input[type="text"],
.send-btn {
	padding: 4px 8px;
	border: 0;
	outline: 0;
}
.send-btn {
	background-color: transparent;
	cursor: pointer;
}
.send-btn:hover i {
	color: #79c7c5;
	transform: scale(1.2);
}

/* container */
.container {
	width: 23rem;
	max-height: 25rem;
    position: absolute;
	/* position: relative; */
	/* top: 50%;
	left: 50%; */
	/* transform: translate(-50%, -50%); */
	z-index: 0;
	border-radius: 10px;
	background-color: #f9fbff;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	overflow: hidden;
    overflow-y: scroll;
}

/* chat_box */
.chat_box {
	display: flex;
	flex-direction: column;
	height: 100%;
}
.chat_box > * {
	padding: 16px;
}

/* head */
.head {
	display: flex;
	align-items: center;
}
.head .user {
	display: flex;
	align-items: center;
	flex-grow: 1;
}
.head .user .avatar {
	margin-right: 8px;
}
.head .user .avatar img {
	display: block;
	border-radius: 50%;
}
.head .bar_tool {
	display: flex;
}
.head .bar_tool i {
	padding: 5px;
	width: 30px;
	height: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
}

/* body */
.body {
	flex-grow: 1;
	background-color: #eee;
}
.body .bubble {
	position: relative;
	display: inline-block;
	padding: 10px;
	margin-bottom: 5px;
	border-radius: 15px;
}
.body .bubble p {
	color: #f9fbff;
	font-size: 14px;
	text-align: left;
	line-height: 1.4;
}
.body .incoming {
	text-align: right;
}
.body .incoming .bubble {
	background-color: #5088ab;
}
.body .outgoing {
	text-align: left;
}
.body .outgoing .bubble {
	background-color: #79c7c5;
}

/* foot */
.foot {
	display: flex;
}
.foot .msg {
	flex-grow: 1;
}

@keyframes bounce {
	50% {
		transform: translate(0, 5px);
	}
	100% {
		transform: translate(0, 0);
	}
}
.ellipsis {
	display: inline-block;
	width: 5px;
	height: 5px;
	border-radius: 50%;
	background-color: #b7b7b7;
}
.dot_1 {
	/* animation: name duration timing-function delay iteration-count */
	animation: bounce 0.8s linear 0.1s infinite;
}
.dot_2 {
	animation: bounce 0.8s linear 0.2s infinite;
}
.dot_3 {
	animation: bounce 0.8s linear 0.3s infinite;
}

.chat-log_author {
	margin: 0 0rem 0em;
	font-size: .9rem;
	font-weight: bold;
}

.msg-action {
	padding: 0;
}
