.footer {
    width: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #ebeff3;
    position: fixed;
    flex-direction: row;
    justify-content: flex-end;
    z-index: 99;
    display: flex;
    height: 3rem;
    line-height: 4em;
    flex-grow: 1;
    bottom: 0;
    color: gray;
}