.tab-item {
    padding: 1rem;
    margin: 0.1rem;
    text-align: center;
    border: 1px solid #BDBDBD;
    border-radius: 1rem;
    background-color: #EAEFF4;
    flex-grow: 1;
    font-weight: bold;
    cursor: pointer;
}

.active {
    background-color: white;
    color: #006CB0;
    cursor: auto;
}