.large {
  width: 20rem;
}

.small {
  width: 7rem;
}

.medium {
  width: 12rem;
}

.btn {
    border: 2px solid black;
    border-radius: 0.7rem;
    background-color: white;
    color: black;
    margin: 0.5rem;
    padding: 0.4rem;
    font-size: 0.8rem;
    cursor: pointer;
  }

.primary {
    border-color: #006CB0;
    color: #006CB0;
}

button:disabled,
button[disabled]{
  cursor: default;
}

.filled-square-button {
  margin: 0.5rem;
  padding: 0.4rem;
  height: 2rem;
  background-color: #006CB0;
  color: white;
  border: 1px solid #006CB0;
  border-radius: 50px;
  box-sizing: border-box;
  font-weight: bold;
  cursor: pointer;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
}

.outlined {
  background-color: white !important;
  border-radius: 50px;
}
.filled {
  color: white !important;
}

.rounded{
  border-radius: 50px;
  /* height: 1.5rem; */
  border-width: 0;
}

.green {
  background-color: #00BF6C;
  border-color: #00BF6C;
  color: #00BF6C;
}

.red {
  background-color: #C0392B;
  border-color: #C0392B;
  color: #C0392B
}

.secondary {
  background-color: #EAEFF4;
  border-width: 1px !important;
  color: black !important;
}

.sidebar-menu-item {
  border: none;
  background: none;
  cursor: pointer;
  padding: 10px 14px 10px 14px;
  margin: 2px;
  color: #006CB0;
}

.sidebar-menu-item:hover {
  border-bottom: #006CB0 solid 1px;
}

.logout-button {
  color: #006CB0;
  background: none;
  border: none;
  font-size: large;
  cursor: pointer;
  margin-bottom: '2px';
}
