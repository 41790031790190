.login-page{
    height:100%;
    width:100%;
    position:absolute;
    background-color: #CDCDCD;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-body {
    background-color: white;
    width:55%;
    /* height:0%; */
    padding: 3rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.header {
    color: #006CB0;
    font-weight: bold;
    font-size: 1.5rem;
    text-align: center;
}

.normal-text {
    margin-left: 0.5rem;
    font-size: 0.7rem;
}
