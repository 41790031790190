.chip {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    padding: .5rem;
    margin: 0.5rem;
    font-size: 1rem;
    border-radius: 25px;
    background-color: #EAEFF4;
    cursor: pointer;
}

.closebtn {
    padding-left: .6rem;
    color: #888;
    font-weight: bold;
    /* float: right; */
    font-size: 1.5rem;

}
.active-chip {
    background-color: #00bf6c5b;
}

.hide-chip {
    background-color: #c03a2b63;
}

.closebtn:hover {
    color: #000;
}