.accordion {
    padding: 0 2rem;

}

.accordion-header {
    margin: .07rem;
    padding: 1rem;
    border: 2px solid #BDBDBD;
    background-color: #EAEFF4;
    border-radius: .7rem;
    display: flex;
    
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.open {
    border-radius: .7rem .7rem 0 0;
    margin-bottom: 0;
}

.accordion-body {
    display: none;
    padding: .5rem;
    border-radius: 0 0 .7rem .7rem;
    border: 2px solid #BDBDBD;
    border-top: 0;
}

.open + .accordion-body {
    display: block;
}



