.filter{
    background-color: #EAEFF4;
    width: 100%;
    display: flex;
    justify-content:space-between;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    cursor: pointer;
}

.filter-body {
    /* background-color: #EAEFF4; */
    display: none;
    margin-top: 10px;
    border: 1px solid #BDBDBD;
    border-radius: .5rem;
    transition: 0.5s;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.filter-body-open {
    display: flex;
}

.filter-body-left {
    display: flex;
    width: 78%;
    flex-wrap: wrap;
}

.filter-body-right {
    display: flex;
    width: 22%;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.add-expense-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 2;
    /* align-items: center; */
}

.add-expense-row {
    display: flex;
    justify-content:start;
    align-items:center;
    flex-direction: row;
}

.expense-file-container {
    width: 41.5rem;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.center-item {
    justify-content:center !important;
    gap: 2rem
}

.highlight-text {
    font-weight: bold;
    color: #006CB0;
    font-size: 1rem
}

.split-container {
    padding: .3rem 0rem;
    /* width: 42rem; */
    border: 3px solid #006CB0;
    border-radius: 3px;
}

@media screen and (max-width: 850px){
    .filter-body-left {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
    }
    
    .filter-body-right {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content:start;
    }
}