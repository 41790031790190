.sidebar {
    /* width: 4rem; */
    margin: 0;
    padding: 0;
    /* overflow: hidden; */
    background-color: #EAEFF4;
    position: sticky;
    flex-direction: column;
    justify-content: space-between;
    z-index: 100;
    display: flex;
    height: 100vh;
    line-height: 1rem;
    flex-grow: 1;
    top: 0;
    left: 0;
    border-right: #006CB0 solid 1px;
}

.sidebar-content{
    /* overflow: hidden; */
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content:center;
    height: auto;
    margin-top:.1rem;
    /* position: relative; */
    padding-left: 15px;
    padding-right: 15px;
    
}

.user-icon {
    margin-top: 1rem;
    padding: 1rem 1.4rem;
    border: 2px solid #006CB0;
    background-color: #006CB0;
    color: white;
    border-radius: 50%;
    font-size: 1.5rem;
    font-weight: bold;
}

p {
    padding:0;
    margin: 0;
}

.side-item {
    color: #006CB0;
    margin-top: 0.8rem;
    cursor: pointer;
    padding-left: 3px;
    
}
.side-item:hover {
    border-left: #006CB0 solid 1px;
}

.details-sidebar {
    background-color: #EAEFF4;
}

.hide {
    display: none;
}

.details-side-item {
    color: #006CB0;
}

.details-menue-item {
    display: "flex";
    justify-content: "center";
    width:"7rem";
}

.sub-menu-wrapper {
    position: absolute;
}

.expand-button {
    color: #006CB0;
    margin-top: 0.8rem;
    cursor: pointer;
    position: absolute;
    top: -3px;
    right: -2rem;
}

