
.select-style{
    background-color: #EAEFF4;
    box-sizing: border-box;
    border: 2px solid #CDCDCD;
    border-radius: 5px;
    padding: 0.5rem;
    margin: 0.5rem;
    font-size:1rem;
}

.large-select {
    width: 26rem;
}

.medium-select {
    width: 20rem;
}

.small-select {
    width: 10rem;
}

.select-lebel {
    display:inline;
    position: absolute;
    padding: 0 1px;
    background-color: #EAEFF4;
    color: #006CB0;
    font-size:14px;
    transition: 0.5s;
    border-radius: 4px;
    z-index: 10;
    top: 0px;
    left: 10px;
    font-weight: 600;

}
/* select:focus + .select-lebel {
    display: inline;
    top: 0px;
    left: 10px;
    z-index: 10;
    font-weight: 600;
    color: #006CB0;

} */

@media only screen and (max-width: 950px){
    .medium-select {
        width: 15rem
    }
    .large-select {
        width: 16rem;
    }
}