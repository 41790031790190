.layout-title{
    border-bottom: 1px solid black;
    padding: 0.4rem;
    font-weight: bold;
    font-size: 1.5rem;
    display: flex;
    justify-content:space-between;
    align-items: center;
}

.tab {
    padding-top: 0.5rem;
    width: 100%;
    display: flex;
}

.align-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: .5rem;
}
