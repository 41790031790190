.navbar-body {
    width: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #EAEFF4;
    position: fixed;
    flex-direction: row;
    justify-content: flex-end;
    z-index: 99;
    display: flex;
    height: 3rem;
    line-height: 4em;
    flex-grow: 1;
    top: 0;
    left: 0;
}

.navbar{
    padding-right: 1rem;
    overflow: hidden;
    display: flex;
    align-items:center;
    justify-content: space-between;
    left: 1.5em;
    height: auto;
}

.nav-item {
    margin: 0.5rem;
    color: #006CB0;
    cursor: pointer;
}