.modal {
    display: none;
    z-index: 99;
    position: fixed;
    top: 3rem;
    width: 100%;
    height: 100vh;
    border: 1px solid white;
    /* border-radius: 1rem;  */
    /* overflow: auto; */
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-body {
    overflow-x: auto;
}

.modal-open {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.modal-content {
    padding: 1rem 4rem;
    background-color: #fefefe;
    border-radius: 0 0 .5rem .5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modal-header {
    padding: 0 1rem;
    /* width: 40%; */
    background-color: white;
    color: black;
    border-bottom: 2px solid #BDBDBD;
    display: flex;
    border-radius: .5rem .5rem 0 0;
    justify-content: space-between;
    align-items: center;
}

.close {
    color: #aaaaaa;
    float: right;
    font-size: 2rem;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}