.role-permissioins {
    display: flex;
    width: 50%;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.role-content {
    border: 1px solid #BDBDBD;
    border-radius: .7rem;
    display: flex;
    margin: .5rem;
    flex-wrap: wrap;
}
.small-role-content {
    width: 26rem;
}

.role-permissioin-body {
    width: 90%;
    padding: 2px;
    display: flex;
    justify-content: flex-start;
    gap: .5rem
}