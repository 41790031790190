::placeholder {
    color: #CDCDCD;
    opacity: 1; /* Firefox */
}

.large-inp {
    width: 26rem;
}

.medium-inp {
    width: 20rem
}

.large-inline-inp {
    width: 12.5rem
}

.small-inp {
    width: 10rem;
}

.extra_large-inp {
    width: 42.5rem;
}

.input-body {
    display: 'flex';
    flex-direction:"column";
    position: "relative";
    /* align-items: center; */
}
.text-input{
    background-color: #EAEFF4;
    box-sizing: border-box;
    border: 2px solid #CDCDCD;
    border-radius: 5px;
    padding: 0.5rem;
    margin: 0.5rem;
    font-size:1rem;
    z-index: 0;
    position: relative;
}

.error {
    border: 2px solid #C0392B !important;
}

.healp-text {
    color: #006CB0 !important;
    margin-top: -5px !important;
    margin-bottom: 5px !important;
}

.label{
    position: absolute;
    top: 0px;
    left: 10px;
    padding: 0 1px;
    background-color: #EAEFF4;
    color: #DADCE0;
    font-size:14px;
    transition: 0.5s;
    border-radius: 4px;
    z-index: 0;
    font-weight: 600;
    color: #006CB0;
}

.required {
    position: absolute;
    top: 10px;
    right: -1px;
    padding: 0 1px;
    /* background-color: #EAEFF4; */
    color: red;
    font-size:1.2rem;
    transition: 0.5s;
    border-radius: 4px;
    z-index: -1;
}

.label-date{
    top: 1px;
}

/* .input:not(:placeholder-shown) + .label-date{
    display: inline;
} */
/* 
.text-input:not(:placeholder-shown) + .label {
    top: 0px;
    left: 10px;
    z-index: 10;
    font-weight: 600;
    color: #006CB0;
} */

/* input:read-only + .label {
    top: 0px;
    left: 10px;
    z-index: 10;
    font-weight: 600;
    color: #006CB0;
} */

input:read-only {
    border: 2px solid rgb(35, 35, 35) !important;
}

/* input:focus::placeholder {
    transition: 1s;
    color: transparent;
} */

.error-message {
    margin-left: 0.5rem;
    padding: 0;
    color: #C0392B;
    font-size: .8rem;
}

@media only screen and (max-width: 950px){
    .medium-inp {
        width: 15rem
    }
    .large-inp {
        width: 16rem;
    }
    
    .extra_large-inp {
        width: 32.5rem;
    }
}