.header-edit-container {
    border: 1px solid #BDBDBD;
    border-radius: 1rem;
    margin: .5rem 0;
}

.custom-table-container {
    display: flex;
}

.information-block {
    border: 1px solid #BDBDBD;
    border-radius: .5rem;
    margin: 1rem 5rem;
    padding: .3rem;
}

.information-row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: .3rem 4rem;
    justify-content: space-between;
    /* align-items: center; */
    gap: .5rem;
}

.information-block-header{
    margin-left: 2rem;
}

.display-none {
    display: none;
}

.border-bottom {
    border-bottom: 1px #BDBDBD solid;
}

.border {
    border: 1px solid #BDBDBD;
}

.border-radius {
    border-radius: .6rem;
}

.column-box{
    width: 100%;
    display: flex;
    padding-left: 1rem;
    align-items: center;
    justify-content:space-between;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    cursor: pointer;
}

.align-left{
    display: flex;
    align-items: center;
}

.align-space-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

/* .information-row > div{
    display: flex;
    justify-content:center;
} */