.alert {
    display: none;
    position: fixed;
    padding: .8rem;
    /* background-color: #f44336; */
    color: white;
    opacity: 1;
    border-radius: .5rem;
    transition: opacity 0.6s;
    /* margin-bottom: 15px; */
    bottom: 2rem;
    left: 50%;
    z-index: 999;
    transform: translate(-50%, 0);
    -webkit-animation-name: slideIn;
    -webkit-animation-duration: 0.4s;
    animation-name: slideIn;
    animation-duration: 0.4s
}

.alert.danger {background-color: #f44336;}
.alert.success {background-color: #04AA6D;}
.alert.info {background-color: #2196F3;}
.alert.warning {background-color: #ff9800;}

.closebtn {
    margin-left: 15px;
    color: white;
    font-weight: bold;
    float: right;
    font-size: 22px;
    line-height: 20px;
    cursor: pointer;
    transition: 0.3s;
}

.alert-close {
    display: none;
}

.closebtn:hover {
    color: black;
}

@-webkit-keyframes slideIn {
    from {bottom: -300px; opacity: 0} 
    to {bottom: 2rem; opacity: 1}
}

@keyframes slideIn {
    from {bottom: -300px; opacity: 0}
    to {bottom: 2rem; opacity: 1}
}