.table-body {
    border: 1px solid #BDBDBD;
    border-radius: .6rem;
    margin: .3rem 1rem;
    overflow-x:auto;
    /* width: 100%; */
}
.thead {
    border: 1px solid #BDBDBD;
    /* border-radius: .6rem; */
    /* flex-grow: 1; */
    background-color: #EAEFF4;
}

th {
    padding: 8px;
}

td {
    text-align: center;
    padding: .4rem;
}

.active-row {
    background-color: #EAEFF4;
}
tr:hover{
    background-color:#EAEFF4;
}

.small-header {
    width: 4rem;
}

.medium-header {
    width: 10rem;
}

.large-header {
    width: 17rem;
}

.more {
    position: relative;
}

.more-menue {
    position: absolute;
    /* top: 15rem; */
}

.gap-1 {
    gap: 1rem;
    padding: .5rem
}

#column1:hover {
    background-color:#EAEFF4;
}

#column2:hover {
    background-color:#EAEFF4;
}

#column3:hover {
    background-color:#EAEFF4;
}

.border-black td {
    border: 1px solid black;
}